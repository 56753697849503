/** @jsx jsx */
import { jsx } from "theme-ui"

const Footer = () => (
  <footer
    className="site-footer"
    sx={{
      bg: "siteColor",
    }}
  >
    <div className="container">
      <div className="grids col-1 sm-2">
        <a className="button contact-button-footer" href="mailto:roza064@yandex.ru">roza064@yandex.ru</a>
        <a className="button contact-button-footer" href="tel:+79139765863">7 (913) 976-58-63 <br/> (запись через WhatsApp)</a>
      </div>
    </div>
  </footer>
)

export default Footer
